import { configureFullStory, initFullStory } from "utils/fullstory";
import { configureSentry, initSentry } from "utils/sentry";

async function telemetry() {
  // let fullStoryInitializationError: Error;
  // try {
  //   // FullStory must be initialized first for the Sentry integration to work
  //   await initFullStory();
  // } catch (e) {
  //   fullStoryInitializationError = e;
  // } finally {
  //   await initSentry(fullStoryInitializationError);
  // }
  // await configureSentry();
  // await configureFullStory();
}

// This script has its own webpack bundle and module
// side-effect to make crash reporting as resilient as
// possible isolated from other module/script failures
telemetry();
